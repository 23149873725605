/* Fonts */

@font-face {
  font-family: "concourse_c2_tabregular";
  src: url("concourse_c2_tab_regular-webfont.woff2") format("woff2"),
    url("concourse_c2_tab_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_c2_tabbold";
  src: url("concourse_c2_tab_bold-webfont.woff2") format("woff2"),
    url("concourse_c2_tab_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_c3_tabbold";
  src: url("concourse_c3_tab_bold-webfont.woff2") format("woff2"),
    url("concourse_c3_tab_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_c3_tabregular";
  src: url("concourse_c3_tab_regular-webfont.woff2") format("woff2"),
    url("concourse_c3_tab_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_c4_tabbold";
  src: url("concourse_c4_tab_bold-webfont.woff2") format("woff2"),
    url("concourse_c4_tab_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_c4_tabregular";
  src: url("concourse_c4_tab_regular-webfont.woff2") format("woff2"),
    url("concourse_c4_tab_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 1, 2020 */

@font-face {
  font-family: "concourse_t2_tabbold_italic";
  src: url("concourse_t2_tab_bold_italic-webfont.woff2") format("woff2"),
    url("concourse_t2_tab_bold_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_t2_tabbold";
  src: url("concourse_t2_tab_bold-webfont.woff2") format("woff2"),
    url("concourse_t2_tab_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_t2_tabitalic";
  src: url("concourse_t2_tab_italic-webfont.woff2") format("woff2"),
    url("concourse_t2_tab_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_t2_tabregular";
  src: url("concourse_t2_tab_regular-webfont.woff2") format("woff2"),
    url("concourse_t2_tab_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_t3_tabbold_italic";
  src: url("concourse_t3_tab_bold_italic-webfont.woff2") format("woff2"),
    url("concourse_t3_tab_bold_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_t3_tabbold";
  src: url("concourse_t3_tab_bold-webfont.woff2") format("woff2"),
    url("concourse_t3_tab_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_t3_tabitalic";
  src: url("concourse_t3_tab_italic-webfont.woff2") format("woff2"),
    url("concourse_t3_tab_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "concourse_t3_tabregular";
  src: url("concourse_t3_tab_regular-webfont.woff2") format("woff2"),
    url("concourse_t3_tab_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 1, 2020 */

@font-face {
  font-family: "equity_caps_abold";
  src: url("equity_caps_a_bold-webfont.woff2") format("woff2"),
    url("equity_caps_a_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "equity_caps_aregular";
  src: url("equity_caps_a_regular-webfont.woff2") format("woff2"),
    url("equity_caps_a_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "equity_text_abold_italic";
  src: url("equity_text_a_bold_italic-webfont.woff2") format("woff2"),
    url("equity_text_a_bold_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "equity_text_abold";
  src: url("equity_text_a_bold-webfont.woff2") format("woff2"),
    url("equity_text_a_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "equity_text_aitalic";
  src: url("equity_text_a_italic-webfont.woff2") format("woff2"),
    url("equity_text_a_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "equity_text_aregular";
  src: url("equity_text_a_regular-webfont.woff2") format("woff2"),
    url("equity_text_a_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icomoon";
  src: url("icomoon.eot?6w3cw4");
  src: url("icomoon.eot?6w3cw4#iefix") format("embedded-opentype"),
    url("icomoon.ttf?6w3cw4") format("truetype"),
    url("icomoon.woff?6w3cw4") format("woff"),
    url("icomoon.svg?6w3cw4#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-github:before {
  content: "\eab0";
}
.icon-linkedin:before {
  content: "\eaca";
}
